import CentralLogs from "../modules/user/applications-logs/central-logs.js";
import SystemLogs from "../modules/user/applications-logs/system-logs.js";
import UserScheduler from "../modules/user/applications-logs/user-scheduler.js";
import Dashboard from "../modules/user/dashboard/index.js";
import Expenses from "../modules/user/expenses/index.js";
import Goal from "../modules/user/goal/index.js";
import LTV from "../modules/user/ltv/index.js";
import MarketplaceCredentials from "../modules/user/marketplace-credentials/index.jsx";
import ProductProfit from "../modules/user/product-profit/index.js";
import Product from "../modules/user/products/index.js";
import Reports from "../modules/user/reports/index.js";
import SalesControlling from "../modules/user/sales-controlling/index.js";

export const Users = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },

  {
    path: "products",
    element: <Product />,
  },
  {
    path: "expenses",
    element: <Expenses />,
  },
  {
    path: "sales-controlling",
    element: <SalesControlling />,
  },
  {
    path: "reports",
    element: <Reports />,
  },
  {
    path: "ltv",
    element: <LTV />,
  },
  {
    path: "goal",
    element: <Goal />,
  },
  {
    path: "product-profit",
    element: <ProductProfit />,
  },
  {
    path: "central-logs",
    element: <CentralLogs />,
  },
  {
    path: "system-logs",
    element: <SystemLogs />,
  },
  {
    path: "user-scheduler",
    element: <UserScheduler />,
  },
  {
    path: "marketplace-credentials",
    element: <MarketplaceCredentials />,
  },
];
