import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Image,
  Input,
  message,
  Progress,
  Row,
  Select,
  Skeleton,
  Switch,
  Tag,
  theme,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import Table2 from "../../../components/tables/table2";
import { AddCommas, ConvertParams, FormatDateRange } from "../../../config";
import { DymmyImage } from "../../../config/fakeData";
import { CardViewWrapper, Wrapper } from "./style";
import { useNavigate } from "react-router-dom";

const { Text, Title, Paragraph } = Typography;
const { Panel } = Collapse;

const CardView = ({
  title,
  setSelectedCard,
  list,
  selectedCard,
  cardRows,
  summaryLoading,
  othersList,
  getTable,
  firstCard,
}) => {
  const { token } = theme.useToken();
  const [expanded, setExpanded] = useState(false);

  const {
    revenue,
    total_unit,
    filter_name,
    organic_unit,
    profit_per_unit,
    roi,
    ppc_per_unit,
    acos,
    sessions,
    conversion_rate,
    end_date,
    total_costs,
    filter_key,
    sd_unit,
    total_profit,
    sp_unit,
    refund_unit,
    other,
    profit_pect,
    cost_pect,
    start_date,
  } = list?.[title?.indexKey] || {};

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const panelStyle = {
    background: "transparent",
    borderRadius: token.borderRadiusLG,
    border: "none",
    width: "100%",
  };

  const loading = summaryLoading;
  return (
    <CardViewWrapper span={24}>
      <Card
        title={
          <div
            style={{
              background: title?.color,
              paddingInline: "20px",
              paddingTop: "10px",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {loading ? (
                <>
                  {" "}
                  <Skeleton.Input className="mb-1" size="large" active />
                </>
              ) : (
                <div>
                  <Text style={{ color: "#ffffff", fontSize: "20px" }}>
                    {filter_name || "-"}
                  </Text>{" "}
                  <br />
                  <span
                    style={{
                      color: "#ffffff",
                      fontWeight: 400,
                      fontSize: "13px",
                    }}
                  >
                    {FormatDateRange(start_date, end_date)}
                  </span>
                </div>
              )}

              {filter_key === "today" ? (
                <> </>
              ) : loading ? (
                <Skeleton.Button className="mb-1" active />
              ) : (
                <Text
                  className="values-text"
                  style={{ color: "#28a745", fontSize: "16px" }}
                >
                  15.8% ↑{" "}
                  <p style={{ color: "#fff", fontSize: "12px" }}>Last year</p>
                </Text>
              )}
            </div>
          </div>
        }
        hoverable
        headStyle={{
          borderRadius: "12px",
          boxShadow: `${
            selectedCard?.title === title?.title
              ? `${title?.color}7a 0px 7px 29px 0px`
              : ""
          }`,
          borderBottom: "none",
          border: `${
            selectedCard?.title === title?.title
              ? `3px solid ${title?.color}`
              : "3px solid #fff"
          } `,
        }}
        style={{
          borderRadius: "8px",
        }}
        onClick={(e) => {
          getTable({
            start_date: start_date,
            end_date: end_date,
          });
          setSelectedCard({
            color: title?.color,
            title: filter_name,
            date: FormatDateRange(start_date, end_date),
          });
        }}
        bodyStyle={{
          marginTop: "10px",
          borderRadius: "12px",
          border: `${
            selectedCard?.title === title?.title
              ? "3px solid #32a4ec"
              : "3px solid #fff"
          } `,
          boxShadow: `${
            selectedCard?.title === title?.title
              ? "#32a4ec57 0px 7px 29px 0px"
              : ""
          }`,
        }}
      >
        {loading ? (
          Array(4)
            .fill(0)
            .map((_, index) => (
              <Row key={index}>
                <Col flex="auto">
                  <Skeleton.Input className="mb-1" size="large" active />
                </Col>
                <Col flex="none">
                  <Skeleton.Button className="mb-1" size="large" active />
                </Col>
              </Row>
            ))
        ) : (
          <>
            <Row justify="space-between">
              <Col>
                <Text strong>Revenue</Text>
              </Col>
              <Col>
                <Text strong style={{ color: "#004085", fontSize: "20px" }}>
                  {AddCommas(revenue || 0)} €
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>Units</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>{AddCommas(total_unit || 0)}</Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />
                <Row justify="space-between">
                  <Col>
                    <Text>Organic</Text>
                  </Col>
                  <Col>
                    <Text strong style={{ color: "#004085" }}>
                      {AddCommas(organic_unit || 0)}
                    </Text>
                  </Col>
                </Row>
                <Divider className="my-2" />
                <Row justify="space-between">
                  <Col>
                    <Text>Sponsored Products</Text>
                  </Col>
                  <Col>
                    <Text strong style={{ color: "#004085" }}>
                      {AddCommas(sp_unit || 0)}
                    </Text>
                  </Col>
                </Row>
                <Divider className="my-2" />
                <Row justify="space-between">
                  <Col>
                    <Text>Sponsored Display</Text>
                  </Col>
                  <Col>
                    <Text strong style={{ color: "#004085" }}>
                      {AddCommas(sd_unit || 0)}
                    </Text>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            <Row justify="space-between">
              <Col span={12}>
                <Text>Refund</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(refund_unit || 0)}
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>Costs</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>{AddCommas(total_costs || 0)}€</Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />
                {cardRows.map((item, index) => (
                  <div key={index}>
                    <Row justify="space-between">
                      <Col style={{ maxWidth: "100px" }}>
                        <Text>{item.name}</Text>
                      </Col>
                      <Col
                        span={16}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {console.log(
                            firstCard,
                            item.name,
                            list?.[title?.indexKey]?.[item.value],
                            "firstCardfirstCard"
                          )}
                          {}
                          <Text
                            style={{
                              color:
                                "PPC" === item.name
                                  ? firstCard?.ppc <
                                    list?.[title?.indexKey]?.[item.value]
                                    ? "red"
                                    : "#1f1f1f"
                                  : "Storage" === item.name
                                  ? firstCard?.storage_cost <
                                    list?.[title?.indexKey]?.[item.value]
                                    ? "red"
                                    : "#1f1f1f"
                                  : "Refund" === item.name
                                  ? firstCard?.refund <
                                    list?.[title?.indexKey]?.[item.value]
                                    ? "red"
                                    : "#1f1f1f"
                                  : "#1f1f1f",
                            }}
                          >
                            {AddCommas(
                              list?.[title?.indexKey]?.[item.value] || 0
                            )}
                            €
                          </Text>
                          {item.percentage && (
                            <Text
                              style={{
                                color:
                                  "PPC" === item.name
                                    ? firstCard?.ppc <
                                      list?.[title?.indexKey]?.[item.value]
                                      ? "red"
                                      : "#1f1f1f"
                                    : "Storage" === item.name
                                    ? firstCard?.storage_cost <
                                      list?.[title?.indexKey]?.[item.value]
                                      ? "red"
                                      : "#1f1f1f"
                                    : "Refund" === item.name
                                    ? firstCard?.refund <
                                      list?.[title?.indexKey]?.[item.value]
                                      ? "red"
                                      : "#1f1f1f"
                                    : "#1f1f1f",
                              }}
                            >
                              {AddCommas(cost_pect?.[item.percentage] || 0)}%
                            </Text>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Divider className="my-2" />
                  </div>
                ))}
              </Panel>
            </Collapse>
            <Divider className="my-2" />
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>Others</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>{AddCommas(other || 0)}€</Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />

                {othersList.map((item, index) => (
                  <div key={index}>
                    <Row justify="space-between">
                      <Col style={{ width: "min-content" }} flex="auto">
                        <Text>{item.name}</Text>
                      </Col>
                      <Col
                        // span={16}
                        span={5}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Text
                            style={{
                              color: item.color || "inherit",
                              display: "flex",
                            }}
                          >
                            {AddCommas(
                              list?.[title?.indexKey]?.other_fees?.[
                                item.value
                              ] || 0
                            )}
                            €
                          </Text>
                        </div>
                      </Col>
                    </Row>
                    <Divider className="my-2" />
                  </div>
                ))}
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            <Row justify="space-between" align="middle">
              <Col>
                <Text strong style={{ color: "#28a745" }}>
                  Profit
                </Text>
              </Col>
              <Col>
                <Text strong style={{ color: "#28a745" }}>
                  {AddCommas(total_profit || 0)} €
                </Text>
              </Col>
              <Col>
                <Text style={{ color: "#28a745" }}>
                  {AddCommas(profit_pect)}%
                </Text>
              </Col>
              <Col>
                <Text
                  onClick={toggleExpand}
                  style={{ cursor: "pointer", color: "#28a745" }}
                >
                  {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </Text>
              </Col>
            </Row>

            {expanded && (
              <div
                style={{
                  marginTop: 10,
                  backgroundColor: "#d5fbddcc",
                  padding: 10,
                }}
              >
                <Row justify="space-between">
                  <Col span={8}>
                    <Text>Profit per unit</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(profit_per_unit || 0)} €</Text>
                  </Col>
                  <Col span={8}>
                    <Text>ROI</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(roi || 0)}%</Text>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={8}>
                    <Text>PPC per unit</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(ppc_per_unit || 0)} €</Text>
                  </Col>
                  <Col span={8}>
                    <Text>ACOS</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(acos || 0)}%</Text>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={8}>
                    <Text>Sessions</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(sessions || 0)}</Text>
                  </Col>
                  <Col span={8}>
                    <Text>Conversion</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(conversion_rate || 0)}%</Text>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </Card>
    </CardViewWrapper>
  );
};
export default function Dashboard(props) {
  const { userData } = props;
  const [filterLoading, setFilterLoading] = useState(true);
  const [filterData, setFilterData] = useState({});
  const [summaryList, setSummaryList] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({
    filterCard: null,
    sku_lists: [],
  });
  const navigate = useNavigate();
  const [goalData, setGoalData] = useState({});
  const [goalDataLoading, setGoalDataLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [productLoading, setProductLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState({
    title: null,
    color: null,
    date: null,
  });
  const [firstCard, setFirstCard] = useState({});
  const [productListFilter, setProductListFilter] = useState([]);
  const [productListFilterLoading, setProductListFilterLoading] =
    useState(true);
  const [filterSearch, setFilterSearch] = useState(null);

  const DashboardProductsAction = async (data) => {
    setProductLoading(true);
    const response = await MakeApiCall(
      `dashboard/products${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setProductList(response?.data || []);
      setProductLoading(false);
    } else {
      setProductList([]);
      message.warning(response?.message);
      setProductLoading(false);
    }
  };
  const cardDetails = {
    datefilter1: [
      {
        cardTitle: [
          {
            color: "#601A3E",

            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#4B0076",
            indexKey: 2,
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter2: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            indexKey: 4,
            color: "#C9A2C9",
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#4B0076",
            indexKey: 2,
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter3: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 2,
            color: "#4B0076",
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter4: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 1,
            color: "#311465",
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 2,
            color: "#4B0076",
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
  };
  const GetSalesControllingAction = async () => {
    setFirstCard([]);
    const response = await MakeApiCall(
      `sales-controlling`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setFirstCard(
        response?.data?.records
          ?.filter((d, i) => i !== 0)
          ?.reduce((acc, item) => {
            acc[item?.salesControllerParam?.slug] = item.param_value;
            return acc;
          }, {})
      );
    } else {
      setFirstCard([]);
      message.warning(response?.message);
    }
  };

  const GetProductsListAction = async (data) => {
    setProductListFilter([]);
    setProductListFilterLoading(true);
    const response = await MakeApiCall(
      `products?is_all=1`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setProductListFilterLoading(false);
      setProductListFilter(response?.data?.records || []);
    } else {
      setProductListFilterLoading(false);
      setProductListFilter([]);
      message.warning(response?.message);
    }
  };

  const DashboardSummaryAction = async (data) => {
    setSummaryLoading(true);
    const response = await MakeApiCall(
      `dashboard/summary${ConvertParams({
        ...data,
        date_filter: data?.filterCard || data?.date_filter,
      })}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      if (response?.data?.length > 0) {
        setProductLoading(true);
        DashboardProductsAction({
          start_date: response?.data?.[0]?.start_date,
          end_date: response?.data?.[0]?.end_date,
          sku_lists: data?.sku_lists,
        });
        setSelectedFilters((prev) => {
          setSelectedCard({
            title: response?.data?.[0]?.filter_name,
            color: cardDetails?.[prev?.filterCard]?.[0]?.cardTitle?.[0]?.color,
            date: FormatDateRange(
              response?.data?.[0]?.start_date,
              response?.data?.[0]?.end_date
            ),
          });

          return prev;
        });
      }
      setSummaryList(response?.data || []);
      setSummaryLoading(false);
    } else {
      message.warning(response?.message);
      setSummaryLoading(false);
    }
  };
  const GoalMonthlyProfitAction = async () => {
    setGoalDataLoading(true);
    setGoalData({});
    const response = await MakeApiCall(
      `goal/monthly-profit-goal`,
      "get",
      null,
      true,
      {},
      userData?.token
    );
    if (response?.status) {
      setGoalDataLoading(false);
      setGoalData(response?.data?.profit_data || {});
    } else {
      setGoalDataLoading(false);
      setGoalData({});
      message.warning(response?.message);
    }
  };

  const getDashboardFilterAction = async () => {
    setFilterLoading(true);
    const response = await MakeApiCall(
      `dashboard/get-filter`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      const obj =
        Object.entries(response?.data || {})?.map(([key, value]) => ({
          label: filterTitle?.[key],
          value: key,
        })) || {};
      setFilterData(obj);
      if (obj?.length > 0) {
        setSelectedFilters({ filterCard: obj?.[0]?.value });
        setSummaryLoading(true);
        DashboardSummaryAction({
          date_filter: obj?.[0]?.value,
          sku_lists: selectedFilters?.sku_lists?.join(","),
        });
      } else {
        setSummaryLoading(false);
      }

      setFilterLoading(false);
    } else {
      message.warning(response?.message);
      setFilterLoading(false);
    }
  };

  useEffect(() => {
    GetProductsListAction();
    getDashboardFilterAction();
    GoalMonthlyProfitAction();
    GetSalesControllingAction();
    return () => {};
  }, []);

  const cardRows = [
    {
      name: "Cost of Goods",
      value: "cogs",
      percentage: "cogs_pect",
    },
    {
      name: "Amazon Commission",
      value: "commission",
      percentage: "commission_pect",
    },
    {
      name: "Shipping",
      value: "shipping",
      percentage: "shipping_pect",
    },
    {
      name: "PPC",
      value: "ppc",
      percentage: "ppc_pect",
      color: "red",
    },
    {
      name: "Storage",
      value: "storage",
      percentage: "storage_pect",
    },
    {
      name: "Refund",
      value: "refund",
      percentage: "refund_pect",
    },

    {
      name: "VAT",
      value: "vat",
      percentage: "vat_pect",
    },
  ];

  const othersList = [
    {
      name: "ReturnPostageBilling Postage",
      value: "ReturnPostageBilling_Postage",
    },
    {
      name: "ReturnPostageBilling FuelSurcharge",
      value: "ReturnPostageBilling_FuelSurcharge",
    },
    {
      name: "ReturnPostageBilling OversizeSurcharge",
      value: "ReturnPostageBilling_OversizeSurcharge",
    },
    {
      name: "ReturnPostageBilling DeliveryAreaSurcharge",
      value: "ReturnPostageBilling_DeliveryAreaSurcharge",
    },
    {
      name: "FBARemovalFee",
      value: "FBARemovalFee",
    },
    {
      name: "Subscription",
      value: "Subscription",
    },
    {
      name: "Shipping Service",
      value: "Shipping Service",
    },
    {
      name: "Coupon Payment",
      value: "Coupon Payment",
    },
    {
      name: "Deal Payment",
      value: "Deal Payment",
    },
    {
      name: "other",
      value: "other",
    },
  ];
  const columns = [
    {
      title: "Product",

      width: 400,
      render: (text, row) => (
        <div
          className="d-flex align-items-center"
          style={{
            background: "#f0f5ff",
            padding: "10px",
            borderRadius: "12px",
          }}
        >
          <Image
            style={{ borderRadius: "18px" }}
            src={row?.image_url || DymmyImage}
            width={75}
            height={75}
            onError={(e) => {
              e.target.src = DymmyImage;
            }}
          />
          <div className="ms-3">
            <div className="d-flex">
              <Tag color="#AF69ED" bordered={false}>
                {row?.asin || "-"}
              </Tag>
              <Tag color="#C8A2C9" bordered={false}>
                {row?.sku || "-"}
              </Tag>
            </div>
            <div
              className="my-2"
              style={{
                width: "350px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
              }}
            >
              {row?.product_name || "-"}
            </div>
            <div>
              <Tag color="#957A81" bordered={false}>
                {row?.price}€
                {row?.product_type ? ` - ${row?.product_type}` : ""}
              </Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Units Sold",
      dataIndex: "unit_sold",
      key: "unit_sold",
    },
    {
      title: "Refunds",
      dataIndex: "refund",
      key: "refund",
      render: (text) => `${text?.toLocaleString()}€`, // Format refunds as currency
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      render: (text) => `${text?.toLocaleString()}€`, // Format sales as currency
    },
    {
      title: "Ads",
      dataIndex: "ads",
      key: "ads",
      render: (text) => `${text?.toLocaleString()}€`, // Format ads as currency
    },
    {
      title: "Net Profit",
      dataIndex: "net_profit",
      key: "net_profit",
      render: (text) => `${text?.toLocaleString()}€`, // Format net profit as currency
    },
    {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      render: (text) => `${text?.toLocaleString()}€`, // Format net profit as currency
    },
    {
      title: "PPC",
      dataIndex: "ppc",
      key: "ppc",
      render: (text) => `${text?.toLocaleString()}€`, // Format net profit as currency
    },
    {
      title: "BSR",
      dataIndex: "bsr",
      key: "bsr",
    },
    {
      title: "Info",
      dataIndex: "info",
      key: "info",
      render: (text) => <Tag>{text || "-"}</Tag>,
    },
  ];

  const filterTitle = {
    datefilter1:
      "Today / Yesterday / 2 days ago / Last 7 days / Last 14 days / Last 30 days",
    datefilter2:
      "Today / Yesterday / 2 days ago / This month / Last month / 2 month ago",
    datefilter3:
      "This month / Last month / 2 month ago / 3 month ago / 4 month ago / 5 month ago",
    datefilter4:
      "This month / Last month / 2 month ago / This year / Last year / 2 years ago",
  };
  const filterOption = (input, option) => {
    return (
      option?.asin1?.toLowerCase().includes(input?.toLowerCase()) ||
      option?.seller_sku?.toLowerCase().includes(input?.toLowerCase()) ||
      option?.item_name?.toLowerCase().includes(input?.toLowerCase())
    );
  };
  return (
    <Wrapper className="fadeInUp">
      <div className="mb-3 prosess-card">
        <Row className="d-flex justify-content-between" gutter={[16, 16]}>
          <Col
            className="d-flex justify-content-start align-items-center"
            // span={8}
            flex={"none"}
          >
            <Typography>
              <Title level={4}>
                Monthly profit goal:{" "}
                <Icon
                  style={{ position: "relative", top: "3px" }}
                  icon="material-symbols:info"
                />
              </Title>

              <Paragraph className="mb-0">
                <small>*</small>
                <span style={{ fontSize: "0.8rem" }}>
                  Average Profit Over the Last 12 Month
                </span>
              </Paragraph>
            </Typography>
          </Col>
          <Col className="d-flex align-items-center " flex={"auto"}>
            <div className="d-grid px-10" style={{ width: "100%" }}>
              <Progress
                percent={parseInt(goalData?.profit_pect || 0)}
                percentPosition={{
                  align: "end",
                  type: "inner",
                }}
                size={["100%", 20]}
                strokeColor="#2e9520"
              />
              <div className="d-flex justify-content-center align-items-center mt-2">
                <span>
                  {AddCommas(parseInt(goalData?.monthly_profit || 0))}€
                </span>
                &nbsp;<b>/</b>&nbsp;
                <b>
                  {AddCommas(parseInt(goalData?.monthly_profit_goal || 0))}€
                </b>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-end align-items-center"
            // span={4}
            flex={"none"}
          >
            <Button
              onClick={() => {
                navigate("/goal");
              }}
              style={{ borderRadius: "7px" }}
              type="primary"
            >
              Analyse
            </Button>
          </Col>
        </Row>
      </div>
      <div className="card mb-3" style={{ borderRadius: "12px" }}>
        <div className="card-header d-flex align-items-center justify-content-between">
          <div className="card-title">
            <Select
              placeholder="Search by name, tag, SKU, ASIN"
              showSearch
              loading={productListFilterLoading}
              filterOption={filterOption}
              dropdownStyle={{ width: "40%" }}
              onChange={(e) => {
                setSelectedFilters({
                  ...selectedFilters,
                  sku_lists: e?.map((d) => d?.value),
                });
                DashboardSummaryAction({
                  ...selectedFilters,
                  sku_lists: e?.map((d) => d?.value)?.join(","),
                });
              }}
              value={selectedFilters?.seller_sku}
              labelInValue
              optionLabelProp="label"
              style={{ width: "350px", minWidth: "350px" }}
              maxTagCount="responsive"
              mode="multiple"
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip
                  overlayStyle={{
                    pointerEvents: "none",
                  }}
                  title={omittedValues.map(({ label }) => label).join(", ")}
                >
                  <span>Hover Me</span>
                </Tooltip>
              )}
            >
              {productListFilter.map((product, i) => (
                <Select.Option
                  key={i}
                  value={product.seller_sku}
                  label={product.item_name}
                  // sku={product?.seller_sku}
                  {...product}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "40px", height: "40px" }}>
                      <Avatar
                        src={product.image_url || DymmyImage}
                        shape="square"
                        size={40}
                      />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div
                        style={{
                          maxWidth: "100%",
                          whiteSpace: "normal",
                          wordBreak: "break-all",
                          overflowWrap: "breakWord",
                          hyphens: "auto",
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          // width: "126px",
                        }}
                      >
                        {product.item_name || "-"}
                      </div>
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="card-toolbar d-flex align-items-center justify-content-end">
            <div
              className="ms-3"
              style={{ border: "1px solid #bfbfbf", borderRadius: "30px" }}
            >
              <Select
                options={filterData}
                loading={filterLoading}
                value={selectedFilters?.filterCard}
                onChange={(e) => {
                  const obj = {
                    ...selectedFilters,
                    filterCard: e,
                  };
                  setSelectedFilters(obj);
                  DashboardSummaryAction({
                    ...obj,
                    sku_lists: selectedFilters?.sku_lists?.join(","),
                  });
                }}
                className="ms-3"
                variant="borderless"
              />
            </div>
            <Select className="ms-3" placeholder="Select Marketplace" />
          </div>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        {cardDetails?.[selectedFilters?.filterCard]?.map((d, i) => (
          <Col span={8} key={i}>
            <Row gutter={[16, 16]}>
              {d?.cardTitle?.map((d) => (
                <CardView
                  firstCard={firstCard}
                  summaryLoading={summaryLoading}
                  setSelectedCard={setSelectedCard}
                  title={d}
                  othersList={othersList}
                  list={summaryList}
                  getTable={DashboardProductsAction}
                  selectedCard={selectedCard}
                  cardRows={cardRows}
                />
              ))}
            </Row>
          </Col>
        ))}
      </Row>
      <div className="card p-5 pt-2 mt-5">
        <div className="card-header d-flex align-items-center mb-2">
          <div className="card-title">
            {selectedCard?.title || "-"} &nbsp;
            <Tag color={selectedCard?.color}>{selectedCard?.date || "-"}</Tag>
          </div>
          <div className="card-toolbar">
            <Text
              className="values-text"
              style={{ color: "#28a745", fontSize: "16px" }}
            >
              15.8% ↑{" "}
              <p style={{ color: "#000", fontSize: "12px" }}>Last year</p>
            </Text>
          </div>
        </div>
        <Table2
          dataSource={productList}
          columns={columns}
          scroll={{ x: "max-content" }}
          loading={productLoading}
          pagination={false} // You can enable pagination if needed
        />
      </div>
    </Wrapper>
  );
}
