import styled from "styled-components";

export const Wrapper = styled.div`
  .custom-card {
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .card-content {
    display: flex;
    flex-wrap: wrap; /* Ensures that elements will wrap to the next line if needed */
    padding: 15px;
  }

  .card-row {
    display: flex;
    flex-direction: column; /* Stack period and value vertically */
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 50%; /* Each card-row will take up 50% width, so two pairs fit in one row */
    padding-right: 15px; /* Add space between columns */
    box-sizing: border-box;
  }

  .card-period {
    color: #8c8c8c; /* Muted gray color for period labels */
    font-size: 14px;
  }

  .card-value {
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }

  .ant-card .ant-card-body {
    padding: 0 !important;
    border-radius: 0px !important;
  }
`;
