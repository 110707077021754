import React from "react";
import { Wrapper } from "./style";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Table,
  Tag,
  Checkbox,
  Tooltip,
  Input,
  Image,
  Card,
  Col,
  Row,
  Select,
  Button,
} from "antd";

import Table1 from "../../../components/tables/table1";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DymmyImage } from "../../../config/fakeData";

export default function LTV() {
  const cardData = [
    {
      title: "Acc. sales per buyer (€)",
      headerColor: "#4f8bd6", // Blue background for the first card
      values: [
        { period: "Last 3 months", value: "€ 16.57" },
        { period: "Last 6 months", value: "€ 17.00" },
        { period: "Last 12 months", value: "€ 17.03" },
        { period: "Last 24 months", value: "€ 17.15" },
      ],
    },
    {
      title: "Acc. sales per buyer (units)",
      headerColor: "#65acbb", // Green background for the second card
      values: [
        { period: "Last 3 months", value: "1.06" },
        { period: "Last 6 months", value: "1.09" },
        { period: "Last 12 months", value: "1.10" },
        { period: "Last 24 months", value: "1.11" },
      ],
    },
    {
      title: "Acc. orders per buyer (amount)",
      headerColor: "#29aa8a", // Orange background for the third card
      values: [
        { period: "Last 3 months", value: "1.04" },
        { period: "Last 6 months", value: "1.07" },
        { period: "Last 12 months", value: "1.08" },
        { period: "Last 24 months", value: "1.09" },
      ],
    },
  ];

  const dataSource = [
    {
      key: "1",
      product:
        "Quran Planer Deutsch für Kinder islamisches Geschenk Ramadan Eid Koran inkl Poster",
      price: "€ 29.99 · FBA",
      sku: "15-FBFB-FBFB",
      asin: "B07DRQB1CG",
      tags: ["Add"],
      cogs: "€ 3.24",
      fbmShippingCost: "—",
      vatRate: "7.0%",
      hide: false,
      last3Months: "€44.49",
      last6Months: "€35.56",
      last12Months: "€35.75",
      last24Months: "€36.53",
    },
    {
      key: "2",
      product:
        "NIYYAH Quran Planer Deutsch für Kinder Koran islamisches Geschenk Ramadan Eid inkl Poster",
      price: "€ 29.99 · FBA",
      sku: "2O-41ZZ-K3TS",
      asin: "B0C6FSBXHZ",
      tags: ["Add"],
      cogs: "€ 2.89",
      fbmShippingCost: "—",
      vatRate: "7.0%",
      hide: false,
      last3Months: "€44.49",
      last6Months: "€35.56",
      last12Months: "€35.75",
      last24Months: "€36.53",
    },
    // {
    //   key: "3",
    //   product:
    //     "NIYYAH Islamisches Quiz Spiel Quizkarten Allgemeinquiz für Muslime Islam Koran",
    //   sku: "E9-UUVX-5A25",
    //   asin: "B0CD27TLGT",
    //   price: "€ 17.99 · FBA",
    //   tags: ["Add"],
    //   cogs: "€ 2.50",
    //   fbmShippingCost: "—",
    //   vatRate: "19.0%",
    //   hide: false,
    //   last3Months: "€44.49",
    //   last6Months: "€35.56",
    //   last12Months: "€35.75",
    //   last24Months: "€36.53",
    // },
    // {
    //   key: "4",
    //   product:
    //     "NIYYAH Buchstaben Lernspiel ABC Montessori Lernen ab 4 5 6 Jahre Vorschule Zahlen Buchstaben",
    //   price: "€ 15.99 · FBA",
    //   sku: "3U-AI2K-25PV",
    //   asin: "B0CYCDF3WG",
    //   tags: ["#FBA"],
    //   cogs: "€ 2.81",
    //   fbmShippingCost: "—",
    //   vatRate: "19.0%",
    //   hide: false,
    //   last3Months: "€44.49",
    //   last6Months: "€35.56",
    //   last12Months: "€35.75",
    //   last24Months: "€36.53",
    // },
  ];
  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 400,
      render: (text, row) => (
        <div
          className="d-flex align-items-center"
          style={{
            background: "#f0f5ff",
            padding: "10px",
            borderRadius: "12px",
          }}
        >
          <Image
            style={{ borderRadius: "18px" }}
            src={DymmyImage}
            width={75}
            height={75}
          />
          <div className="ms-3">
            <div className="d-flex">
              <Tag color="#AF69ED" bordered={false}>
                {row?.asin || "-"}
              </Tag>
              <Tag color="#C8A2C9" bordered={false}>
                {row?.sku || "-"}
              </Tag>
            </div>
            <div
              className="my-2"
              style={{
                width: "350px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
              }}
            >
              {text || "-"}
            </div>
            <div>
              <Tag color="#957A81" bordered={false}>
                {row?.price}
              </Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Last 3 months",
      dataIndex: "last3Months",
      key: "last3Months",
    },
    {
      title: "Last 6 months",
      dataIndex: "last6Months",
      key: "last6Months",
    },
    {
      title: "Last 12 months",
      dataIndex: "last12Months",
      key: "last12Months",
    },
    {
      title: "Last 24 months",
      dataIndex: "last24Months",
      key: "last24Months",
    },
  ];

  return (
    <Wrapper className="fadeInUp">
      <div className="card fadeInUp mb-7">
        <div className="card-header">
          <div className="card-title d-flex align-items-center justify-content-center">
            LTV
          </div>
          <div className="card-toolbar">
            <Select className="ms-3" placeholder="Select Period" />
            <Select className="ms-3" placeholder="Select Marketplace" />
            <Input
              className="w-250px ms-3"
              // defaultValue={3600}
              prefix="Search..."
              suffix="€"
            />
            <Button className="ms-3" type="primary">
              <Icon icon="ic:outline-plus" />
            </Button>
          </div>
        </div>
      </div>

      {/* Render the top cards */}
      <Row gutter={16}>
        {cardData.map((card, index) => (
          <Col span={8} key={index}>
            <Card className="custom-card">
              <div
                style={{
                  backgroundColor: card.headerColor,
                  color: "white",
                  padding: "20px",
                  textAlign: "start",
                  fontSize: "16px",
                  fontWeight: "semibold",
                }}
              >
                {card.title}
              </div>
              <div className="card-content">
                {card.values.map((item, idx) => (
                  <div key={idx} className="card-row">
                    <span className="card-period">{item.period}</span>
                    <span className="card-value">{item.value}</span>
                  </div>
                ))}
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <Col span={24}>
        <div className="card fadeInUp mt-7">
          <div className="card-header mt-2">
            <div className="card-title d-flex align-items-center justify-content-center">
              Acc. Sales per buyer ($)
            </div>
          </div>
          <div className="card-body p-5">
            <Table1
              scroll={{ x: "max-content" }}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
      </Col>
    </Wrapper>
  );
}
