import { message, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Table2 from "../../../components/tables/table2";
import {
  AddCommas,
  BorderFilter,
  ConvertParams,
  PaginationConfig,
} from "../../../config";
import { MakeApiCall } from "../../../apis";
import {
  ClockCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { CFTooltip } from "../../../components/tooltip/tooltip";

export default function AdminScheduler({ userData }) {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});

  const getTableData = async (data) => {
    try {
      setTableData([]);
      setTableLoading(true);
      const response = await MakeApiCall(
        `admin-scheduler${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.token
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setCurrentPage(response?.data?.pagination?.page || 1);
        setPageSize(response?.data?.pagination?.page_size || 1);
        setTableData(response?.data?.records || []);

        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setTableData([]);
      setTableLoading(false);
    }
  };

  useEffect(() => {
    setTableLoading(true);
    setTableData([]);
    getTableData({
      page: currentPage,
      "page-size": pageSize,
      ...selectedFilters,
    });

    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (item) => {
        return <span>{(currentPage - 1) * pageSize + 1 + item.key}</span>;
      },
    },
    {
      title: "Created At (ET)",
      render: (item) => {
        return (
          <div>
            <span>
              {dayjs(new Date(item?.created_at * 1000)).format("MMM DD, YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Process Type",
      dataIndex: "event_process_type",
      key: "event_process_type",
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
    },
    {
      title: "Days of Inception",
      dataIndex: "previous_days",
      key: "previous_days",
      align: "right",
      render: (e) => {
        return e || 0;
      },
    },
    {
      title: "Daily Frequency",
      dataIndex: "daily_frequency",
      key: "daily_frequency",
      align: "right",
      render: (e) => {
        return e || 0;
      },
    },
    {
      title: "Daily Update Period",
      dataIndex: "update_daily_days",
      key: "update_daily_days",
      align: "right",
      render: (e) => {
        return e || 0;
      },
    },
    {
      title: "Previous Run",
      dataIndex: "previous_status",
      key: "previous_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              bordered={false}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Current day status",
      dataIndex: "frequency_status",
      key: "frequency_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Scheduled Run",
      dataIndex: "frequency_status",
      key: "frequency_status",
      render: (item) => {
        return <span></span>;
      },
    },

    {
      title: "Daily log",
      dataIndex: "update_daily_status",
      key: "update_daily_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Updated At (ET)",
      render: (item) => {
        return (
          <div>
            <span>
              {dayjs(new Date(item?.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="card pulse">
        <div style={{ padding: "0px 15px", marginTop: "10px" }}>
          <Table2
            columns={columns}
            dataSource={tableData?.map((d, i) => ({ ...d, key: i }))}
            scroll={{ x: "max-content" }}
            loading={tableLoading}
            pagination={PaginationConfig({
              pageSize,
              currentPage,
              totalPage,
              onChange: (e, f) => {
                setPageSize(f);
                setCurrentPage(e);
                getTableData({
                  page: e,
                  "page-size": f,
                  ...selectedFilters,
                });
              },
            })}
          />
        </div>
      </div>
    </>
  );
}
