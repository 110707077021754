import { Button, Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MakeApiCall } from "../../../apis";
import { DefaultUserPage, primaryColor, Types } from "../../../config";
import { LoginResponse } from "../../../config/fakeData";
import { setData } from "../../../redux/actions";

const WrapperSignIn = styled.div`
  .login-page {
    display: flex;
    height: 100%;
    background-color: #f5f7fb;
  }

  .login-left {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    text-align: center;
    background: #f3f5ff;
    border-radius: 15px;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }

  .login-illustration {
    width: 400px;
    margin-bottom: 20px;
  }

  .login-left h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }

  .login-left p {
    font-size: 16px;
    color: #666;
  }

  .login-right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(#7695ff33 1px, transparent 1px),
      radial-gradient(#7695ff54 1px, transparent 1px);
    background-size: 22px 22px;
    background-position: 0 0, 11px 11px;
    background-color: #ffffff;
    padding: 40px;
  }
  .fadeInUp {
    padding: 25px;
    display: grid;
    justify-items: center;
    border-radius: 18px;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #e7e7e7;
  }

  .login-right h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
  }

  .login-right p {
    font-size: 14px;
    color: #999;
    margin-bottom: 20px;
  }

  .login-form {
    width: 400px;
  }

  .login-button {
    width: 100%;
    margin-top: 10px;
  }

  .extra-links {
    text-align: center;
    margin-top: 10px;
  }

  .extra-links a {
    color: #1890ff;
  }
`;

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleLogin = (data) => {
    const type = Types?.[data?.data?.user_data?.user_type];
    const obj = {
      user: data?.data,
      loginType: type,
      token: data?.data?.auth_token_data?.access_token,
    };
    dispatch(setData(obj));
    navigate(DefaultUserPage);
  };

  useEffect(() => {
    if (Object.keys(user)?.length !== 0) {
      setLoading(false);
      navigate(DefaultUserPage);
    }
    return () => {};
  }, []);

  const onFinish = async (values) => {
    handleLogin(LoginResponse);
    return;
    setSubmitLoading(true);
    const response = await MakeApiCall("login", "post", { ...values });

    if (response?.status === true) {
      handleLogin(LoginResponse);
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
      message.destroy();
      message.error(response?.message);
    }
  };

  return (
    <WrapperSignIn>
      <div className="login-page">
        {/* Left Section */}
        <Row style={{ height: "100vh", background: `${primaryColor}8c` }}>
          <Col xxl={12} md={12} sm={24} style={{ padding: "15px" }}>
            <div className="login-left">
              <div>
                <img
                  src="/assets/forgot-password.svg"
                  alt="Illustration"
                  className="login-illustration fadeInDown"
                />
                <div></div>
              </div>
              <h1>Fast, Efficient and Productive</h1>
              <p>
                In this kind of post, the blogger introduces a person they've
                interviewed and provides some background information about the
                interviewee and their work.
              </p>
            </div>
          </Col>
          <Col xxl={12} md={12} sm={24} style={{ background: "#FFF" }}>
            <div className="login-right ">
              <div className="fadeInUp">
                <h2 className="mb-7">Forgot Password</h2>
                {/* <p className="mb-7">Your Social Campaigns</p> */}
                <Form
                  name="login"
                  onFinish={onFinish}
                  layout="vertical"
                  className="login-form"
                >
                  <Form.Item
                    name="email"
                    hasFeedback
                    rules={[
                      { required: true, message: "Please input your email!" },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "18px",
                        fontSize: "14px",
                        padding: "12px",
                      }}
                      size="large"
                      placeholder="Email"
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{
                      borderRadius: "18px",
                      fontSize: "14px",
                      padding: "12px",
                    }}
                    className="login-button"
                  >
                    Reset Password
                  </Button>
                </Form>
                <div className="extra-links">
                  <p className="mt-4">
                    <Link to="/sign-in">Sign In</Link>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </WrapperSignIn>
  );
}
