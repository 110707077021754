import AdminScheduler from "../modules/admin/applications-logs/admin-scheduler";
import Dashboard from "../modules/admin/dashboard";
import UserList from "../modules/admin/user-list";

export const Admins = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "manage-user",
    element: <UserList />,
  },
  {
    path: "admin-scheduler",
    element: <AdminScheduler />,
  },
];
