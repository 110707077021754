import { Input, Select, Modal, message, Row, Col, Button } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
// import { MakeApiCall } from "../../../../../../apis";

export default function (props) {
  const { show, close, returnURL, ClientID, userData } = props;

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    seller_name: JSON.parse(localStorage.getItem("user"))?.seller_name,
  });

  const getAllMarketplaceList = async () => {
    const response = await MakeApiCall(
      "marketplaces/get-marketplace-list",
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      let make_ = {};
      response?.data?.forEach((e) => {
        if (!make_[e.region]) {
          make_ = { ...make_, [e.region]: [] };
        }
        make_[e.region]?.push(e);
      });
      setMarketplaceList(make_);

      if (response?.data?.length > 0) {
        const { marketplace, marketplace_id, region, sales_channel } =
          response?.data?.[0];

        setMarketplaceSelected({
          ...marketplaceSelected,
          marketplace: marketplace,
          marketplace_id: marketplace_id,
          region: region,
          sales_channel: sales_channel,
        });
      }
      setMarketplaceLoading(false);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(ClientID);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";

    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);

    getAllMarketplaceList();
    return () => {};
  }, []);

  return (
    <Modal
      open={show}
      onCancel={close}
      width={600}
      title="Add New Advertising Credentials"
      footer={[
        <Button
          type="dashed"
          style={{ borderRadius: "8px" }}
          id="kt_modal_add_customer_cancel"
          onClick={() => {
            close();
          }}
        >
          Discard
        </Button>,

        <a
          id="LoginWithAmazon"
          style={{
            marginLeft: "10px",
            opacity:
              Object.values(marketplaceSelected)?.filter((d) => !d)?.length ===
              0
                ? 1
                : 0.5,
            pointerEvents:
              Object.values(marketplaceSelected)?.filter((d) => !d)?.length ===
              0
                ? "all"
                : "none",
          }}
          onClick={() => {
            if (
              Object.values(marketplaceSelected)?.filter((d) => !d)?.length !==
              0
            ) {
              return;
            }
            let options = {};
            options.scope = "advertising::campaign_management";
            options.response_type = "code";

            window.amazon.Login.authorize(
              options,
              `${returnURL}?marketplace_id=${
                marketplaceSelected?.marketplace_id || ""
              }&seller_name=${marketplaceSelected?.seller_name || ""}`
            );
            close();
          }}
        >
          <img
            border="0"
            alt="Login with Amazon"
            src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
            width="156"
            height="32"
          />
        </a>,
      ]}
    >
      <div className="mt-10" id="kt_modal_add_customer_scroll" style={{}}>
        <Row className="mb-3" gutter={16}>
          <Col span={8}>Seller Account Name</Col>
          <Col span={16}>
            <Input
              placeholder="Enter Seller Account Name"
              style={{ height: "38px" }}
              className="ant_common_input"
              size="large"
              id="seller_account_name"
              value={marketplaceSelected?.seller_name || ""}
              onChange={(e) => {
                setMarketplaceSelected({
                  ...marketplaceSelected,
                  seller_name: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>Marketplace</Col>
          <Col span={16}>
            <Select
              className="me-3"
              style={{ width: "100%" }}
              size="large"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              options={
                marketplaceList &&
                Object.keys(marketplaceList)?.length !== 0 &&
                Object.keys(marketplaceList)?.map((d, i) => {
                  return {
                    label: d,
                    options: marketplaceList[d]?.map((r) => {
                      return { label: r?.name, value: r?.id, ...r };
                    }),
                  };
                })
              }
              onChange={(_, e) => {
                setMarketplaceSelected({
                  ...marketplaceSelected,
                  marketplace: e?.label,
                  marketplace_id: e?.id,
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                });
              }}
              value={marketplaceSelected?.marketplace_id}
              loading={marketplaceLoading}
              placeholder="Select Marketplace"
            />
          </Col>
        </Row>
      </div>
      <div id="amazon-root" />
    </Modal>
  );
}
