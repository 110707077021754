import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";

const UserModal = ({ visible, onCancel, onSubmit, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setLoading(false);
      form.resetFields(); // Reset form fields when the modal is opened
      form.setFieldsValue(initialValues || { user_type: 2 }); // Set initial values or default to user_type = 2
    }
  }, [visible, form, initialValues]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        onSubmit(values);
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  return (
    <Modal
      visible={visible}
      title={initialValues ? "Edit User" : "Create a New User"}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          {initialValues ? "Update" : "Submit"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="userForm">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input autoComplete="off" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name="seller_name"
          label="Seller Name"
          rules={[
            { required: true, message: "Please input your Seller Name!" },
          ]}
        >
          <Input autoComplete="off" placeholder="Enter your Seller Name" />
        </Form.Item>
        <Form.Item
          name="contact_no"
          label="Contact No"
          rules={[
            {
              required: true,
              message: "Please input your contact number!",
            },
            {
              pattern: new RegExp(/^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/),
              message: "Invalid contact number!",
            },
          ]}
        >
          <Input
            autocomplete="new-password"
            autoComplete="new-password"
            placeholder="Contact No"
          />
        </Form.Item>

        {!initialValues && (
          <>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                autocomplete="new-password"
                autoComplete="new-password"
                placeholder="Enter your password"
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm your password" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default UserModal;
