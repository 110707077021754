import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  message,
  Row,
  Col,
} from "antd";

import { useEffect } from "react";
import { Link } from "react-router-dom";
import { MakeApiCall } from "../../../../apis";
// import { MakeApiCall } from "../../../../../../apis";

const SpModal = ({ view, onClose, list, APPID, userData }) => {
  const [region, setRegion] = useState([]);
  const [regionLoading, setRegionLoading] = useState(true);
  const [regionSelected, setRegionSelected] = useState({
    url: null,
    value: null,
  });
  const [selectedMarketplaceList, setSelectedMarketplaceList] = useState([]);

  const [marketplaceSelected, setMarketplaceSelected] = useState(null);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(false);
  const [urls, setUrls] = useState({});
  const [sellerName, setSellerName] = useState(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))?.u_amazon_seller_name || ""
      : ""
  );

  const [primarySelectedMarketplace, setPrimarySelectedMarketplace] =
    useState(false);
  const [accountType, setAccountType] = useState(null);
  const getRegion = async () => {
    setRegionLoading(true);

    const response = await MakeApiCall(
      "get-selling-region",
      "get",
      null,
      true,
      {},
      userData?.token
    );
    setRegionLoading(false);
    if (response?.status) {
      setRegion(
        Object.keys(response?.data)?.map((d, i) => {
          if (i === 0) {
            getMarketplace(d);
            setMarketplaceLoading(true);
            setMarketplaceSelected(null);

            setRegionSelected({
              url: response?.data?.[d]?.url,
              value: d,
            });
          }
          return {
            label: response?.data?.[d]?.name,
            url: response?.data?.[d]?.url,
            value: d,
          };
        })
      );
    } else {
    }
  };

  const getMarketplace = async (data) => {
    setMarketplaceLoading(true);

    const response = await MakeApiCall(
      `marketplaces/get-region-by-marketplace/${data || ""}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );
    if (response?.status === true) {
      setUrls(response?.data?.urls);
      const index = Object.entries(response?.data)?.findIndex(
        (d) => d?.[0] === list?.[0]?.marketplace_id
      );
      if (index !== -1) {
        setMarketplaceSelected(list?.[0]?.marketplace_id);
        setPrimarySelectedMarketplace(true);
      } else {
        setMarketplaceSelected(null);
        setPrimarySelectedMarketplace(true);
        setPrimarySelectedMarketplace(false);
      }
      setMarketplaceList(
        Object.entries(response?.data)?.map((d) => {
          return {
            label: d?.[1],
            value: d?.[0],
          };
        })
      );
      setMarketplaceLoading(false);
    } else {
      setMarketplaceLoading(false);
    }
  };

  useEffect(() => {
    getRegion();
    return () => {};
  }, []);

  return (
    <Modal
      title="Add New"
      open={view}
      width={650}
      centered
      footer={[
        <Button
          type="dashed"
          id="kt_modal_add_customer_submit"
          className="mt-2"
          disabled={
            Object.values(regionSelected)?.filter((d) => !d)?.length !== 0 ||
            !marketplaceSelected
          }
          target="_blank"
          style={{
            pointerEvents:
              Object.values(regionSelected)?.filter((d) => !d)?.length !== 0 ||
              !marketplaceSelected
                ? "none"
                : "auto",
          }}
          href={`${
            regionSelected?.url
          }/apps/authorize/consent?application_id=${APPID}&state=${
            localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user"))
                  ?.u_amazon_seller_email || ""
              : ""
          }!!${sellerName}!!${
            regionSelected?.value
          }!!${marketplaceSelected}!!${accountType}&version=beta`}
          onClick={() => {
            if (
              Object.values(regionSelected)?.filter((d) => !d)?.length === 0
            ) {
              onClose();
              message.destroy();
            } else {
              message.destroy();
              return message.error(
                "please make sure that all required fields are not-empty"
              );
            }
          }}
        >
          <i className="fab fa-amazon text-dark fs-2" /> Login with Amazon
        </Button>,
      ]}
      onCancel={onClose}
    >
      <div className="gap-5 mt-10">
        <Row className="mb-3" gutter={16}>
          <Col span={8}>Seller Account Name</Col>
          <Col span={16}>
            <Input
              onChange={(e) => setSellerName(e.target.value)}
              value={sellerName}
              placeholder="Seller Account Name"
            />
          </Col>
        </Row>
        <Row className="mb-3" gutter={16}>
          <Col span={8}>Region</Col>
          <Col span={16}>
            <Select
              options={region}
              loading={regionLoading}
              value={regionSelected?.value}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(e, r) => {
                // setSelectedMarketplaceList([]);
                getMarketplace(r?.value);
                setMarketplaceLoading(true);
                setMarketplaceSelected(null);
                setRegionSelected({
                  url: r?.url,
                  value: r?.value,
                });
              }}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>Primary Marketplace</Col>
          <Col span={16}>
            <Select
              style={{ width: "100%" }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(e) => {
                setMarketplaceSelected(e);
              }}
              placeholder="Select Primary Marketplace"
              options={marketplaceList}
              // disabled={primarySelectedMarketplace}
              value={marketplaceSelected}
              loading={marketplaceLoading}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default SpModal;
