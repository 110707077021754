import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Card,
  Col,
  Collapse,
  Divider,
  Button,
  Image,
  Row,
  Typography,
  DatePicker,
} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ArrowUpOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Wrapper } from "./style";
import { CalendarOutlined, DownOutlined } from "@ant-design/icons";
import { primaryColor, secondaryColor } from "../../../config";

const { Title, Paragraph, Text } = Typography;

const ProductProfit = () => {
  const CardData = [
    { title: "Revenue", value: "272.160 €", percent: null },
    { title: "PPC", value: "29.807 €", percent: "10,9%" },
    { title: "Cost of Goods", value: "33.807 €", percent: "14,1%" },
    { title: "Storage", value: "8.164 €", percent: "3,6%" },
    { title: "Profit", value: "27.216 €", percent: "10,0%" },
  ];

  const options = {
    chart: {
      type: "column",
      backgroundColor: "#FFF",
    },
    title: {
      style: {
        color: "#333333",
        fontWeight: "bold",
      },
    },
    xAxis: {
      categories: [
        "Jun 27",
        "Jun 30",
        "Aug 19",
        "Aug 24",
        "Jun 25",
        "Jul 04",
        "Jul 07",
        "Jul 14",
        "Jul 17",
        "Jul 24",
        "Jul 29",
        "Aug 03",
        "Aug 06",
        "Aug 11",
        "Aug 16",
        "Aug 23",
        "Aug 28",
        "Sep 02",
      ],
      labels: {
        style: {
          color: "#666666",
          fontSize: "10px",
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#666666",
        },
      },
      gridLineColor: "#e6e6e6",
    },
    series: [
      {
        name: "Revenue",
        data: [
          100000, 95000, 50000, 52000, 60000, 50000, 100000, 110000, 120000,
          160000, 50000, 60000, 70000, 60000, 70000, 90000, 100000, 40000,
        ],
        color: "#C8A2C9",
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: 5,
      },
    },
  };

  const Stackedoptions = {
    chart: {
      type: "column",
      backgroundColor: "#1e272e",
    },
    title: {
      text: "All together",
      style: {
        color: "#ffffff",
      },
    },
    xAxis: {
      categories: [
        "Q1",
        "Q2",
        "Q3",
        "Q4",
        "Q5",
        "Q6",
        "Q7",
        "Q8",
        "Q9",
        "Q10",
        "Q11",
        "Q12",
        "Q13",
        "Q14",
      ],
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#ffffff",
        },
      },
      gridLineColor: "rgba(255,255,255,0.1)",
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Category 1",
        data: [
          4.9, 7.4, 9.5, 5.5, 6.3, 8.5, 6.5, 8.5, 4.2, 5.2, 6.5, 5.5, 5.2, 4.5,
        ],
        color: "#4ecdc4",
      },
      {
        name: "Category 2",
        data: [
          2.9, 3.5, 6.5, 4.2, 4.5, 4.2, 3.5, 4.2, 2.7, 3.8, 4.5, 3.5, 3.8, 3.2,
        ],
        color: "#45b7d8",
      },
      {
        name: "Category 3",
        data: [
          2.1, 5.5, 8.5, 5.5, 4.5, 7.5, 2.5, 1.5, 2.5, 2.5, 3.5, 3.5, 2.5, 2.5,
        ],
        color: "#ff6b6b",
      },
      {
        name: "Category 4",
        data: [
          1.9, 4.5, 5.5, 4.5, 3.5, 4.5, 2.2, 1.5, 1.5, 1.5, 3.5, 1.5, 1.5, 1.8,
        ],
        color: "#f9c74f",
      },
    ],
  };
  return (
    <Wrapper className="fadeInUp">
      <Card
        bordered={false}
        style={{
          backgroundColor: "#FFF", // Light blue background similar to the image
          borderRadius: "12px",
          padding: "0px 0px",
        }}
        className="product-show-card"
      >
        <Row align="middle" className="mt-0 py-4" gutter={16}>
          {/* Product Image */}
          <Col>
            <Image
              width={60}
              height={60}
              src="https://via.placeholder.com/100" // Replace with actual image URL
              alt="Product Image"
              style={{ borderRadius: "8px", border: "2px solid gray" }}
            />
          </Col>

          {/* Text Content */}
          <Col flex="auto">
            <Row className="mt-0">
              <Text strong style={{ fontSize: 16 }}>
                ASIN: YAQQGT82A
              </Text>
              <Text strong style={{ marginLeft: 16, fontSize: 16 }}>
                SKU: UQ-7O6L-4Y6X
              </Text>
            </Row>
            <Row className="mt-0">
              <Text
                type="secondary"
                ellipsis={{
                  tooltip:
                    "Sprühlflasche Öl Sprayer, Olivenöl Sprayer, Sprüher aus Glas,...",
                }}
              >
                Sprühflasche Öl Sprayer, Olivenöl Sprayer, Sprüher aus Glas,...
              </Text>
            </Row>
          </Col>

          {/* Period Button */}
          <Col>
            <DatePicker.RangePicker />
          </Col>
        </Row>
      </Card>
      <Row className="mb-3" gutter={[16, 16]} justify="center" align="stretch">
        {CardData.map((item, index) => (
          <Col key={index} flex="auto">
            <Card className={`custom-card ${index === 0 ? "first-card" : ""}`}>
              <div className="card-value">{item.value}</div>
              <div className="card-title">{item.title}</div>
              {item.percent && (
                <div className="card-percent">{item.percent}</div>
              )}
            </Card>
          </Col>
        ))}
      </Row>

      <div
        style={{
          borderRadius: "8px",
          border: "2px solid #8080802e",
          overflow: "auto",
        }}
        className="chart-container mb-3"
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...options,
            title: {
              text: "Revenue",
            },
          }}
        />
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: "2px solid #8080802e",
          overflow: "auto",
        }}
        className="chart-container mb-3"
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...options,
            title: {
              text: "PPC",
            },
          }}
        />
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: "2px solid #8080802e",
          overflow: "auto",
        }}
        className="chart-container mb-3"
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...options,
            title: {
              text: "Storage Cost",
            },
          }}
        />
      </div>
      <div
        style={{
          borderRadius: "8px",
          border: "2px solid #8080802e",
          overflow: "auto",
        }}
        className="chart-container mb-3"
      >
        <HighchartsReact highcharts={Highcharts} options={Stackedoptions} />
      </div>
    </Wrapper>
  );
};

export default ProductProfit;
