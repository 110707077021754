import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Col,
  Image,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Spin,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import { ConvertParams } from "../../../config";
import { t } from "i18next";
import { DymmyImage } from "../../../config/fakeData";

const { Text } = Typography;

const UniversalSettings = (data) => {
  const {
    asin,
    ppc,
    storage_cost,
    title,
    id,
    refund,
    is_sales_controlling,
    image_url,
  } = data?.d;
  const { Update } = data;

  return (
    <div
      className="card mt-5"
      // style={{ maxWidth: "350px", minWidth: "250px" }}
    >
      <div className="card-header d-flex align-items-center justify-content-between">
        <div className="mt-4">
          <Image
            style={{ borderRadius: "50px" }}
            src={image_url || DymmyImage}
            width={45}
            height={45}
            onError={(e) => {
              e.target.src = DymmyImage;
            }}
          />
        </div>
        <div className="mt-4">
          {title ? (
            <h3 style={{ color: "#777b81", marginRight: "10px" }}>{title}</h3>
          ) : (
            <h3 style={{ color: "#777b81", marginRight: "10px" }}>
              ASIN: {asin}
            </h3>
          )}
        </div>
        <div className="mt-2">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={is_sales_controlling}
            onChange={(e) => {
              Update(id, { is_sales_controlling: e ? 1 : 0 });
            }}
          />
        </div>
      </div>
      <div className="card-body p-5">
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <Text strong>PPC</Text>
          </Col>
          <Col span={12}>
            <Input
              onPressEnter={(e) => {
                console.log(e.target.value, ppc, "e.target.value press");
                if (ppc !== parseFloat(e.target.value)) {
                  Update(id, { ppc: e.target.value });
                }
              }}
              onBlur={(e) => {
                if (ppc !== parseFloat(e.target.value)) {
                  Update(id, { ppc: e.target.value });
                }
              }}
              suffix="%"
              defaultValue={ppc}
            />
          </Col>

          <Col span={12}>
            <Text>Storage cost</Text>
          </Col>
          <Col span={12}>
            <Input
              onPressEnter={(e) => {
                if (storage_cost !== parseFloat(e.target.value)) {
                  Update(id, { storage_cost: e.target.value });
                }
              }}
              onBlur={(e) => {
                if (storage_cost !== parseFloat(e.target.value)) {
                  Update(id, { storage_cost: e.target.value });
                }
              }}
              suffix="%"
              defaultValue={storage_cost}
            />
          </Col>

          <Col span={12}>
            <Text>Refund</Text>
          </Col>
          <Col span={12}>
            <Input
              onPressEnter={(e) => {
                if (refund !== parseFloat(e.target.value)) {
                  Update(id, { refund: e.target.value });
                }
              }}
              onBlur={(e) => {
                if (refund !== parseFloat(e.target.value)) {
                  Update(id, { refund: e.target.value });
                }
              }}
              suffix="%"
              defaultValue={refund}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default function SalesControlling({ userData }) {
  const [valueLoading, setValueLoading] = useState(true);
  const [data, setData] = useState({});
  const [firstCard, setFirstCard] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [searchKey, setSearchKey] = useState(null);

  const GetSalesControllingAction = async () => {
    setValueLoading(true);
    const response = await MakeApiCall(
      `sales-controlling`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setValueLoading(false);
      setFirstCard(response?.data?.records?.filter((d, i) => i !== 0));
      setData(response?.data?.records?.[0] || {});
    } else {
      message.warning(response?.message);
      setValueLoading(false);
    }
  };

  const GetSalesControllingProductsAction = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `sales-controlling/products${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setLoading(false);
      setPage(response?.data?.pagination?.page || 0);
      setPageSize(response?.data?.pagination?.page_size || 0);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };

  const SaveSalesControllingAction = async () => {
    setSubmitLoading(true);
    const response = await MakeApiCall(
      `sales-controlling`,
      "post",
      {
        slug: "monthly_profit_goal",
        param_value: data?.param_value,
      },
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setSubmitLoading(false);
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
      setSubmitLoading(false);
    }
  };

  const SaveSalesControllingUpdateProductAction = async (id, data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      id === -1
        ? `sales-controlling`
        : `sales-controlling/update-product/${id}`,
      id === -1 ? "post" : "put",
      id === -1
        ? {
            slug: Object.entries(data)?.[0]?.[0],
            param_value: Object.entries(data)?.[0]?.[1],
          }
        : data,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      message.destroy();
      GetSalesControllingAction();
      GetSalesControllingProductsAction({
        page: page,
        "per-page": pageSize,
        asin: searchKey,
      });
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    GetSalesControllingAction();
    GetSalesControllingProductsAction({
      page: page,
      asin: searchKey,
      "per-page": pageSize,
    });
    return () => {};
  }, []);

  const loadingCard = !(!loading && firstCard?.length > 0);
  return (
    <>
      <div className="card fadeInUp">
        <div className="card-header d-flex align-items-center justify-content-between">
          {/* Title aligned to the left */}
          <div className="card-title d-flex align-items-center">
            Sales Controlling
          </div>

          {/* Input field and button centered */}
          <div className="d-flex align-items-center justify-content-center">
            <Input
              className="w-300px ms-3"
              value={data?.param_value}
              disabled={valueLoading}
              onChange={(e) => {
                setData({
                  ...data,
                  param_value: e.target.value,
                });
              }}
              prefix={
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.9rem",
                  }}
                >
                  Monthly Profit Goal :
                </span>
              }
              suffix={
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.9rem",
                  }}
                >
                  €
                </span>
              }
              style={{
                fontSize: "0.95rem",
                fontWeight: 500,
              }}
              size="middle"
            />

            <Button
              loading={submitLoading}
              onClick={() => {
                SaveSalesControllingAction();
              }}
              className="ms-3"
              type="primary"
            >
              Save
              {/* <Icon icon="ic:outline-plus" /> */}
            </Button>
          </div>

          {/* Select Marketplace aligned to the right */}
          <div className="card-toolbar d-flex align-items-center">
            <Input
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
              onPressEnter={() => {
                GetSalesControllingProductsAction({
                  page: page,
                  asin: searchKey,
                  "per-page": pageSize,
                });
              }}
              className="w-150px"
              placeholder="Search ASIN"
            />
            <Select className="ms-3" placeholder="Select Marketplace" />
            <Button className="ms-3" type="primary">
              <Icon icon="ic:outline-plus" />
            </Button>
          </div>
        </div>
      </div>

      <Spin spinning={loadingCard}>
        <div style={{ minHeight: "400px" }}>
          <Row justify={"start"} gutter={[16, 16]}>
            {(loadingCard
              ? []
              : [
                  {
                    id: -1,
                    title: "Universal setting",
                    ppc: firstCard?.[0]?.param_value || 0,
                    storage_cost: firstCard?.[1]?.param_value || 0,
                    refund: firstCard?.[2]?.param_value || 0,
                  },
                  ...list,
                ]
            )?.map((d, index) => (
              <Col xxl={6} xl={8} lg={12} sm={24} md={12}>
                <UniversalSettings
                  Update={SaveSalesControllingUpdateProductAction}
                  key={index}
                  d={d}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Spin>
      {/* <div
        className="d-flex  justify-content-evenly fadeInUp "
        style={{ columnGap: "20px", flexWrap: "wrap" }}
      >
        {list?.map((d, index) => (
          <UniversalSettings
            Update={SaveSalesControllingUpdateProductAction}
            key={index}
            d={d}
          />
        ))}
      </div> */}
      {!loadingCard && (
        <Row className="mt-5 mb-5" justify={"end"}>
          <Col>
            <Pagination
              current={page}
              showSizeChanger
              pageSize={pageSize}
              onChange={(e, f, r) => {
                setPage(e);
                setPageSize(f);
                GetSalesControllingProductsAction({
                  page: e,
                  "per-page": f,
                  asin: searchKey,
                });
              }}
              total={totalPage}
            />
          </Col>
        </Row>
      )}
    </>
  );
}
