import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth"; // Adjust the path as necessary

const ProtectedRoute = ({ element, user, setData }) => {
  return isAuthenticated(user) ? (
    React.cloneElement(element, {
      userData: user,
      setData: setData,
    })
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
