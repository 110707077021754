import React from "react";
import MarketplaceView from "./marketplace-view";
import { APPID, ClientID, ORIGIN } from "../../../config";
// import { APPID, ClientID } from "../../../../../config";

const MarketplaceCredentials = (props) => {
  const { userData } = props;
  const APIS = {
    list: "user-credentials",
    generateRefreshToken: "user-credential/generate-refresh-token",
    AdsRefreshToken: "user-credential/generate-refresh-ad-token",
    SPCallBack: "/callback_sp",
    AdsCallBack: "/callbackads",
    APPID: APPID,
    ClientID: ClientID,
    returnURL: `${ORIGIN}/callbackads/`,
    saveProfile: "user-credential/save-ad-profile",
  };

  return <MarketplaceView userData={userData} {...APIS} />;
};

export default MarketplaceCredentials;
