import { message, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Table2 from "../../../components/tables/table2";
import {
  AddCommas,
  BorderFilter,
  ConvertParams,
  PaginationConfig,
} from "../../../config";
import { MakeApiCall } from "../../../apis";
import {
  ClockCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { CFTooltip } from "../../../components/tooltip/tooltip";

export default function CentralLogs({ userData }) {
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    event_name: null,
    event_type: null,
    event_status: null,
  });

  const [eventName, setEventName] = useState([]);
  const [eventType, setEventType] = useState([]);

  const getTableData = async (data) => {
    try {
      setTableData([]);
      setTableLoading(true);
      const response = await MakeApiCall(
        `log/report-logs${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.token
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setCurrentPage(response?.data?.pagination?.page || 1);
        setPageSize(response?.data?.pagination?.page_size || 1);
        setTableData(response?.data?.records || []);

        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setTableData([]);
      setTableLoading(false);
    }
  };

  const eventNameList = async () => {
    try {
      const response = await MakeApiCall(
        `event-name-list`,
        "get",
        null,
        true,
        {},
        userData?.token
      );
      if (response?.status) {
        setEventName(response?.data || {});
      } else {
        setEventName([]);
        message.warning(response?.message);
      }
    } catch (error) {
      setEventName([]);
      message.warning(error?.message);
    }
  };

  const eventTypeList = async () => {
    try {
      const response = await MakeApiCall(
        `event-type-list`,
        "get",
        null,
        true,
        {},
        userData?.token
      );
      if (response?.status) {
        setEventType(response?.data || {});
      } else {
        setEventType([]);
        message.warning(response?.message);
      }
    } catch (error) {
      setEventType([]);
      message.warning(error?.message);
    }
  };

  useEffect(() => {
    setTableLoading(true);
    setTableData([]);
    getTableData({
      page: currentPage,
      "page-size": pageSize,
      ...selectedFilters,
    });

    eventNameList();
    eventTypeList();
    return () => {};
  }, []);

  const columns = [
    {
      title: "Sr. No.",
      width: 100,
      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(currentPage - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log created",
      width: 250,
      render: (item) => {
        return (
          <div>
            <span>
              {dayjs(new Date(item.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Date",
      width: 200,
      render: (item) => {
        return item?.event_date || "-";
      },
    },
    {
      title: "Event Name",
      width: 180,
      // ellipsis: false,
      render: (item) => {
        return (
          <CFTooltip rule>
            <b>{item?.event_name || "N/A"}</b>
          </CFTooltip>
        );
      },
    },
    {
      title: "Event Type",
      width: 150,
      render: (item) => {
        return <b>{item?.event_type || "N/A"}</b>;
      },
    },
    {
      title: "Event Status",
      width: 150,
      render: (item) => {
        return (
          <Tag
            color={
              item.event_status === "PENDING"
                ? "warning"
                : item.event_status === "IN_PROGRESS"
                ? "processing"
                : item.event_status === "DONE"
                ? "success"
                : item.event_status === "FAILED"
                ? "error"
                : item.event_status === "FATAL"
                ? "error"
                : item.event_status === "RULE_APPLIED"
                ? "success"
                : item.event_status === "RULE_NOT_APPLIED"
                ? "warning"
                : item.event_status === "SCHEDULED"
                ? "blue"
                : item.event_status === "CANCELLED"
                ? "error"
                : "error"
            }
            icon={
              item.event_status === "PENDING" ? (
                <ClockCircleOutlined />
              ) : item.event_status === "IN_PROGRESS" ? (
                <SyncOutlined spin />
              ) : item.event_status === "DONE" ||
                item.event_status === "RULE_APPLIED" ||
                item.event_status === "SCHEDULED" ? (
                <CheckCircleOutlined />
              ) : item.event_status === "FAILED" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "FATAL" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "CANCELLED" ? (
                <CloseCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
          >
            {item.event_status || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Priority",
      width: 120,
      render: (item) => {
        return (
          <>
            {item?.priority == 1 ? (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#ff0000" }}
                ></div>
                High
              </div>
            ) : item?.priority == 2 ? (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#faad14" }}
                ></div>
                Medium
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#52c41a" }}
                ></div>
                Low
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Profile ID",
      width: 200,
      render: (item) => {
        return <b>{item?.profile_id || "N/A"}</b>;
      },
    },
    {
      title: "Marketplace",
      width: 200,
      render: (item) => {
        return <b>{item?.marketplace || "N/A"}</b>;
      },
    },
    {
      title: "Updated at",
      width: 220,
      render: (item) => {
        return (
          <div>
            <span>
              {dayjs(new Date(item.updated_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Report Requested",
      width: 200,
      render: (d) => {
        console.log(d.status, "d.status");

        return (
          <Tag
            color={d.status === 0 ? "processing" : "success"}
            icon={
              d.status === 0 ? <ClockCircleOutlined /> : <CheckCircleOutlined />
            }
          >
            {d.status === 0 ? "Pending" : "Done"}
          </Tag>
        );
      },
    },
  ];

  const userCentralLogEventStatus = [
    {
      label: "PENDING",
      value: "PENDING",
    },
    {
      label: "IN_PROGRESS",
      value: "IN_PROGRESS",
    },
    {
      label: "DONE",
      value: "DONE",
    },
    {
      label: "FAILED",
      value: "FAILED",
    },
    {
      label: "FATAL",
      value: "FATAL",
    },
    {
      label: "CANCELLED",
      value: "CANCELLED",
    },
    {
      label: "NOT_FOUND",
      value: "NOT_FOUND",
    },
  ];

  return (
    <>
      <div className="card pulse mb-3" style={{ border: BorderFilter }}>
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="card-title d-flex " style={{ flexWrap: "wrap" }}>
            <div className="position-relative">
              <Select
                allowClear
                className="w-200px me-2"
                value={selectedFilters.event_name}
                onChange={(e) => {
                  const obj = { ...selectedFilters, event_name: e };
                  setSelectedFilters(obj);
                  setTableLoading(true);
                  setTableData([]);
                  setCurrentPage(1);
                  getTableData({
                    page: 1,
                    "page-size": pageSize,
                    ...obj,
                  });
                }}
                options={Object.entries(eventName || {})?.map(
                  ([key, value]) => ({ label: value, value: key })
                )}
                placeholder={"Select Event Name"}
              />
            </div>
            <div className="position-relative">
              <Select
                allowClear
                className="w-200px me-2"
                value={selectedFilters.event_type}
                onChange={(e) => {
                  const obj = { ...selectedFilters, event_type: e };
                  setSelectedFilters(obj);
                  setTableLoading(true);
                  setTableData([]);
                  setCurrentPage(1);
                  getTableData({
                    page: 1,
                    "page-size": pageSize,
                    ...obj,
                  });
                }}
                options={eventType?.map((d) => ({ label: d, value: d }))}
                placeholder={"Select Event Type"}
              />
            </div>
            <div className="position-relative">
              <Select
                allowClear
                className="w-200px me-2"
                value={selectedFilters.event_status}
                onChange={(e) => {
                  const obj = { ...selectedFilters, event_status: e };
                  setSelectedFilters(obj);
                  setTableLoading(true);
                  setTableData([]);
                  setCurrentPage(1);
                  getTableData({
                    page: 1,
                    "page-size": pageSize,
                    ...obj,
                  });
                }}
                options={userCentralLogEventStatus}
                placeholder={"Select Event Status"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card pulse">
        <div style={{ padding: "0px 15px", marginTop: "10px" }}>
          <Table2
            columns={columns}
            dataSource={tableData}
            scroll={{ x: "max-content" }}
            loading={tableLoading}
            pagination={PaginationConfig({
              pageSize,
              currentPage,
              totalPage,
              onChange: (e, f) => {
                setPageSize(f);
                setCurrentPage(e);
                getTableData({
                  page: e,
                  "page-size": f,
                  ...selectedFilters,
                });
              },
            })}
          />
        </div>
      </div>
    </>
  );
}
