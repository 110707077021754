import React from "react";
import { ActionBTNWrapper, ImportWrapper, Wrapper } from "./style";

export const AddBTN = ({ children, ...rest }) => {
  return <Wrapper {...rest} children={children} />;
};
export const ImportBTN = ({ children, ...rest }) => {
  return <ImportWrapper {...rest} children={children} />;
};
export const ActionBTN = ({ children, ...rest }) => {
  return (
    <ActionBTNWrapper>
      <button {...rest} className="Download-button">
        <span>{children}</span>
      </button>
    </ActionBTNWrapper>
  );
};
