import { Table } from "antd";
import React from "react";
import ConfigColumns from "../columns-config";
import { Table1Wrapper } from "./style";

export default function Table1(props) {
  const { dataSource, columns } = props;

  return (
    <Table1Wrapper>
      <Table dataSource={dataSource} columns={columns} {...props} />
      {/* <ConfigColumns columns={columns} /> */}
    </Table1Wrapper>
  );
}
