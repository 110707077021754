import { Tooltip } from "antd";
import { useEffect, useState } from "react";

export const CFTooltip = ({ row = 1, rule, children, placement }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [id] = useState(() => `overflow-text-${Math.random()}`);

  const checkOverflow = () => {
    const element = document.getElementById(id);
    if (element) {
      setIsOverflowing(element.scrollHeight > element.offsetHeight);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [id]);

  const style = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: `${row * 1.5}em`, // Adjust this multiplier as needed
    display: "-webkit-box",
    WebkitLineClamp: row,
    WebkitBoxOrient: "vertical",
    lineHeight: "1.5em", // Adjust this as needed
  };

  return (
    <Tooltip
      title={rule && isOverflowing ? children : ""}
      placement={placement || "topLeft"}
    >
      <div id={id} style={style}>
        {children}
      </div>
    </Tooltip>
  );
};
