import { Icon } from "@iconify/react/dist/iconify.js";
import { Checkbox, DatePicker, Dropdown, Input, Menu, Select } from "antd";
import React from "react";

import { EllipsisOutlined } from "@ant-design/icons";
import Table2 from "../../../components/tables/table2";
import Table1 from "../../../components/tables/table1";

export default function Expenses() {
  const dataSource = [
    {
      key: "1",
      date: "23/10/2024",
      type: "Annually",
      name: "App iOS",
      advertisingCost: "",
      product: "Selected products: 3",
      marketplace: "Amazon.de",
      amount: "€ 100.00",
    },
    {
      key: "2",
      date: "15/09/2024",
      type: "Monthly",
      name: "App Android",
      advertisingCost: "",
      product: "Selected products: 5",
      marketplace: "Amazon.co.uk",
      amount: "€ 50.00",
    },
    {
      key: "3",
      date: "01/08/2024",
      type: "Quarterly",
      name: "Web App",
      advertisingCost: "",
      product: "Selected products: 2",
      marketplace: "Amazon.com",
      amount: "€ 200.00",
    },
    {
      key: "4",
      date: "12/07/2024",
      type: "Annually",
      name: "Desktop App",
      advertisingCost: "",
      product: "Selected products: 4",
      marketplace: "Amazon.fr",
      amount: "€ 150.00",
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Advertising Cost",
      dataIndex: "advertisingCost",
      key: "advertisingCost",
      render: () => <Checkbox />,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Actions",
      key: "actions",
      render: () => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">Action 1</Menu.Item>
            </Menu>
          }
        >
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];
  return (
    <div className="card fadeInUp">
      <div className="card-header mt-2">
        <div className="card-title d-flex align-items-center justify-content-center">
          Expenses
        </div>
        <div className="card-toolbar">
          <Input
            prefix={<Icon width={20} icon={"iconamoon:search-duotone"} />}
            placeholder="Expenses Name"
            className="w-200px"
          />
          <DatePicker.RangePicker className="ms-3" />
          <Select className="ms-3" placeholder="Select Products" />
          <Select className="ms-3" placeholder="Select Category" />
          <Select className="ms-3" placeholder="Select Marketplace" />
        </div>
      </div>
      <div className="card-body p-5">
        <Table2
          scroll={{ x: "max-content" }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
}
